import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form/immutable';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import { TextFieldRedux } from 'dan-components/Forms/ReduxFormMUI';
import styles from '../../../components/Forms/user-jss';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import TextEditor from './TextEditor';
import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import * as Validator from '../../../utils/validate';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CloseIcon from '@material-ui/icons/Close';
import {CheckboxRedux} from '../../../components/Forms/ReduxFormMUI';
import ListItemText from '@material-ui/core/ListItemText';
import InputAdornment from "@material-ui/core/InputAdornment";
// Floara形式のフッター作成時必要
// import TextField from '@material-ui/core/TextField';
// import TextEditor from 'dan-components';
// import InlineTextEditor from 'dan-components';

function DeliveryForm(props) {

  const {
    classes,
    handleSubmit,
    isShow
  } = props;

  const accessToken = props.getAccessToken;
  const plan = props.companyData.plan;

  const [ipAddresses, setIpAddresses] = useState(props.companyData.ip_addresses_attributes.length == 0 ? [''] : props.companyData.ip_addresses_attributes);

  const handleAddIpAddress = () => {
    setIpAddresses([...ipAddresses, '']);
  };

  const handleIpAddressChange = (index, value) => {
    const newIpAddresses = [...ipAddresses];
    newIpAddresses[index] = value;
    setIpAddresses(newIpAddresses);
  };

  return (
    <section className={classes.formWrap}>
      <form onSubmit={handleSubmit}>
        {/* TODO メールアドレスとフッター（html版）が必要になった場合コメント解除
        　　　　　 フッター（html版）はまだうまく動作できていないため改修もお願いします*/}

        {/* <div>
          <FormControl className={classes.formControl}>
            <Field
              name="email"
              component={TextFieldRedux}
              placeholder="メールアドレス"
              label="メールアドレス"
              required
              validate={[Validator.required]}
              className={classes.field}
            />
          </FormControl>
        </div> */}
        {/* <div>
          <FormControl className={classes.formControl}>
            <Field
              name="mail_signature"
              component={TextEditor}
              label="メールフッター（HTML)"
              validate={[Validator.required]}
              className={classes.field}
            />
          </FormControl>
        </div> */}
        <div>
          <FormControl className={classes.formControl}>
            <Field
              name="mail_signature"
              className={classes.field}
              component={TextFieldRedux}
              placeholder="メールフッター"
              label="メールフッター"
              multiline={true}
              minRows={10}
            />
          </FormControl>
        </div>
        <div>
          <FormControl className={classes.formControl}>
            <p>Cookie使用同意</p>
            <Field name='cookie_policy' component={TextEditor} className={classes.field} accessToken={accessToken}/>
          </FormControl>
        </div>
        <div>
          <FormControl className={classes.formControl}>
            <p>特定商取引法</p>
            <Field name='law' component={TextEditor} className={classes.field} accessToken={accessToken} />
          </FormControl>
        </div>
        <ListItemText primary="3Dセキュア決済"/>
        <FormControl className={classes.formControl}>
          <Field
            name="three_d_secure_key"
            component={TextFieldRedux}
            placeholder="3Dセキュアキー"
            label="3Dセキュアキー"
            validate={[Validator.byte16]}
          />
          <Field
            name="three_d_iv"
            component={TextFieldRedux}
            placeholder="3Dセキュアベクトル"
            label="3Dセキュアベクトル"
            validate={[Validator.byte16]}
          />
        </FormControl>
        <ListItemText primary="QR決済"/>
        <FormControl className={classes.formControl}>
          <Field
            name="qr_key"
            component={TextFieldRedux}
            placeholder="QR決済キー"
            label="QR決済キー"
            validate={[Validator.byte16]}
          />
          <Field
            name="qr_iv"
            component={TextFieldRedux}
            placeholder="QR決済ベクトル"
            label="QR決済ベクトル"
            validate={[Validator.byte16]}
          />
        </FormControl>
        {plan == 'premium' &&
          <>
            <ListItemText primary="ApplePay決済"/>
            <FormControl className={classes.formControl}>
              <Field
                name="applepay_key"
                component={TextFieldRedux}
                placeholder="ApplePay決済キー"
                label="ApplePay決済キー"
                validate={[Validator.byte16]}
              />
              <Field
                name="applepay_iv"
                component={TextFieldRedux}
                placeholder="ApplePay決済ベクトル"
                label="ApplePay決済ベクトル"
                validate={[Validator.byte16]}
              />
              <Grid item xs={12} sm={6}>
                <FormControl className={classes.formControl}>
                  <Field
                    name="applepay_tenant_id"
                    component={TextFieldRedux}
                    placeholder="ApplePay店舗コード"
                    label="ApplePay店舗コード"
                    validate={[Validator.num2]}
                  />
                </FormControl>
              </Grid>
            </FormControl>
          </>
        }
        <ListItemText primary="コンビニ決済"/>
        <Grid item xs={12} sm={6}>
          <FormControl className={classes.formControl}>
            <Field
              name="pay_limit"
              label="コンビニ決済支払期限"
              type="number"
              fullWidth
              InputProps={{
                endAdornment: <InputAdornment position="end">日</InputAdornment>,
              }}
              validate={[Validator.payLimit]}
              component={TextFieldRedux}
              className={classes.field}
            />
          </FormControl>
        </Grid>
        <ListItemText primary="アクセス許可IPアドレス"/>
        {ipAddresses.map((ipAddress, index) => (
          <div key={index}>
            <Grid container>
              <Grid item xs={11}>
                <FormControl className={classes.formControl}>
                  <Field
                    name={`[ip_addresses_attributes][${index}][ip_address]`}
                    component={TextFieldRedux}
                    placeholder="IPアドレス"
                    label="IPアドレス"
                    value={ipAddress}
                    validate={[Validator.ipAddress]}
                    onChange={(e) => handleIpAddressChange(index, e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid container item xs={1} justifyContent="flex-start" alignItems="center">
                <Tooltip title="選択項目を削除">
                  <FormControlLabel
                    control={(
                      <Field
                        name={`[ip_addresses_attributes][${index}][_destroy]`}
                        component={CheckboxRedux}
                        icon={<CloseIcon className={classes.icon} />}
                        inputProps={{ style: { position: 'static' } }}
                      />
                    )}
                  />
                </Tooltip>
              </Grid>
            </Grid>
          </div>
        ))}
        <div className={classes.btnArea}>
          <Grid container color="primary" justifyContent="center" style={{ marginTop: 20, marginBottom: 30 }}>
            <Tooltip title="項目を追加" placement="top">
              <Fab color="primary" aria-label="add" className={classes.button} onClick={handleAddIpAddress}>
                <AddIcon />
              </Fab>
            </Tooltip>
          </Grid>
        </div>
        <div className={classes.btnArea}>
          <Grid container justifyContent="center">
            {isShow ? '' :
              <Button variant="outlined" color="primary" size="large" component={Link} to="/admin/company/index">
                戻る
              </Button>
            }
            <Button variant="contained" color="primary" size="large" type="submit" disabled={props.invalid}>
              更新
            </Button>
          </Grid>
        </div>
      </form>
    </section>
  );
}

DeliveryForm.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  deco: PropTypes.bool.isRequired,
};
const DeliveryFormReduxed = reduxForm({
  form: 'companyForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
})(DeliveryForm);
const reducer = 'ui';
const FormInit = connect(
  state => ({
    force: state,
    deco: state.getIn([reducer, 'decoration']),
    companyData: state.getIn(["companyReducers", 'companyData'])
  }),
)(DeliveryFormReduxed);
export default withStyles(styles)(FormInit);
