import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// import styles from '../../../components/Forms/user-jss';
import PapperBlock from 'dan-components/PapperBlock/PapperBlock';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '@material-ui/lab/TabPanel';
import TabContext from '@material-ui/lab/TabContext';
import Typography from '@material-ui/core/Typography';
import ServiceForm from './ServiceForm';
import StaffForm from './StaffForm';
import ImageForm from './ImageForm';
import DeliveryForm from './DeliveryForm';
import brand from 'dan-api/dummy/brand';
import { connect } from 'react-redux';
import { onFetchShowCompanyAction, onFetchUpdateCompanyAction, onFetchDeleteCompanyAction } from "../../../redux/actions/company";
import accessToken from '../../../utils/accessToken';
import { withRouter } from 'react-router';
import { withCookies } from 'react-cookie';
import MySnackbar from '../../../components/Notification/StyledNotif';
import TabBackDialog from '../../../components/Dialog/TabBackDialog';
import Loading from '../../../components/Loading/index'

function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '8px'
  },
});

function Edit(props) {
  const description = brand.desc;
  const { match } = props;
  const [isShow, setIsShow] = useState(false);
  const [isMyCompany, setIsMyCompany] = useState(false);
  const [titleName, setTitleName] = useState("");
  const { classes, theme } = props;
  const { companyId, role } = props.internalValues;
  const [value, setValue] = useState("0");
  const [loading, setLoading] = useState(true);
  const [companyData, setCompanyData] = useState([]);
  // メッセージ用
  const location = props.location
  const [notification, setNotification] = useState({
    openStyle: location.message ? true : false,
    message: location.message ? location.message : '',
    variant: location.message ? location.variant : 'info'
  });

  const displayNotification = (message , valiant ='error') => {
    setNotification({
      openStyle: true,
      message: message,
      variant: valiant
    });
  }

  const [modalOpen, setModalOpen] = useState(false);
  const [pendingTab, setPendingTab] = useState(null);

  const openModal = (tabIndex) => {
    setPendingTab(tabIndex);
    setModalOpen(true);
  };

  const handleTabChange = () => {
    setModalOpen(false);
    if (pendingTab !== null) {
      setValue(pendingTab);
      setPendingTab(null);
    }
  };

  function handleClose() {
    setModalOpen(false);
  }

  const resetLoading = () => {
    setLoading(true)
    setLoading(false)
  }

  // 詳細取得
  useEffect(() => {
    let url = props.path
    url = url.split('/')
    // 画面分岐
    if (role === "company") {
      setIsShow(true)
      setTitleName("加盟店情報")
      fetchForm("show")
    } else {
    if (companyId == match.params.id) {
        setIsMyCompany(true)
      }
      setTitleName("加盟店詳細")
      fetchForm()
    }
  }, []);

  const fetchForm = show => {
    props.onFetchShowCompany({
      accessToken: accessToken(props),
      data: {id: isShow || show === 'show' ? companyId : match.params.id},
      onSuccess: async data => {
        if (data.image_url) {
          // 画像URLを元にblobを作成する
          data["image"] = await fetch(data.image_url, { mode: 'cors'}).then(r => r.blob())
          setCompanyData(data)
          resetLoading()
        } else {
          setCompanyData(data)
        }
      },
      onErrorWithNotice: error => {
        displayNotification("詳細情報を取得できませんでした")
      }
    })
    setLoading(false)
  };

  // 更新
  const submitForm = (values) => {
    let dataValues = values
    if (!dataValues.get("image")) {
      dataValues = dataValues.set("image", null)
    }
    if (dataValues.get("plan") !== 'premium') {
      dataValues = dataValues.set("applepay_key", null)
      dataValues = dataValues.set("applepay_iv", null)
      dataValues = dataValues.set("applepay_tenant_id", null)
    }
    dataValues.id = isShow ? companyId : match.params.id
    props.onFetchUpdateCompany({
      accessToken: accessToken(props),
      data: dataValues,
      onSuccess: async data => {
        fetchForm()
        displayNotification("更新が完了しました", 'success')
      },
      onErrorWithNotice: error => {
        displayNotification(error)
      }
    })
  };

  //削除
  const deleteForm = values => {
    props.onFetchDeleteCompany({
      accessToken: accessToken(props),
      data: {id: isShow ? companyId : match.params.id},
      onSuccess: async data => {
        props.history.push({ pathname: '/admin/company/index', message: '加盟店を削除しました', variant: 'success'});
      },
      onErrorWithNotice: error => {
        displayNotification("削除できませんでした")
      }
    })
  };

  const TabComponents = (companyData = {}) => {
    const getAccessToken = accessToken(props);

    return (
      <div className={classes.root}>
      <TabContext value={value}>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={(_, newValue) => openModal(newValue)}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab label="サービス情報" value="0"/>
            <Tab label="担当者情報" value="1"/>
            <Tab label="サービス設定" value="2"/>
            <Tab label="サイト設定" value="3"/>
          </Tabs>
        </AppBar>
        <TabPanel value="0">
          <ServiceForm onSubmit={(values) => submitForm(values)} deleteForm={deleteForm} isShow={isShow} isMyCompany={isMyCompany} initialValues={companyData}/>
        </TabPanel>
        <TabPanel value="1">
          <StaffForm onSubmit={(values) => submitForm(values)} deleteForm={deleteForm} isShow={isShow} initialValues={companyData}/>
        </TabPanel>
        <TabPanel value="2">
          <DeliveryForm onSubmit={(values) => submitForm(values)} deleteForm={deleteForm} isShow={isShow} initialValues={companyData} getAccessToken={getAccessToken}/>
        </TabPanel>
        <TabPanel value="3">
          <ImageForm onSubmit={(values) => submitForm(values)} deleteForm={deleteForm} isShow={isShow} initialValues={companyData}/>
        </TabPanel>
      </TabContext>
      <TabBackDialog isOpen={modalOpen} handleTabChange={handleTabChange} onClose={handleClose}/>
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{brand.name + ' - ' + titleName}</title>
      </Helmet>
      <PapperBlock title="加盟店詳細" icon="ios-document-outline" desc="">
        {loading || props.loadingShowCompany ? TabComponents() : TabComponents(companyData)}
      </PapperBlock>
      <MySnackbar onClose={() => setNotification({...notification, openStyle: false})} notification={notification} />
    </div>
    );
  }

  Edit.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    internalValues: state.getIn(['internalReducers', 'internalValues']),
    loadingShowCompany: state.getIn(['companyReducers', 'loadingShowCompany'])
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchShowCompany: data => {
      dispatch(onFetchShowCompanyAction(data));
    },
    onFetchUpdateCompany: data => {
      dispatch(onFetchUpdateCompanyAction(data));
    },
    onFetchDeleteCompany: data => {
      dispatch(onFetchDeleteCompanyAction(data));
    }
  };
};

const FormInit = connect(
  mapStateToProps,
  mapDispatchToProps
)(Edit);

export default withCookies(withRouter(withStyles(styles, { withTheme: true })(FormInit)));